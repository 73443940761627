<template>
  <div class="achievement-container">
    <div class="item" v-for="item in content.list" :key="item.id">
      <div class="title" @click="goDetails(item)">
        <span class="column" v-if="item.articleTypeName"
          >[{{ item.articleTypeName }}]</span
        >
        <span class="name" v-html="item.title"></span>
      </div>
      <div class="info">
        <span class="auther">{{ item.author }}</span>
        <span class="periodical">{{ item.periodical + "，" }}</span>
        <span>{{ item.journalsYear }}</span>
      </div>
      <IconButton2 :every="item" :curcer="item.id"></IconButton2>
    </div>
    <Page
      v-show="content.total > 8"
      show-total
      :page-size="8"
      :total="+content.total"
      :current="+content.pageNum"
      :styles="{ textAlign: 'center', marginTop: '20px' }"
      @on-change="pageChange"
    >
      {{ "共 " + content.total + " 篇" }}
    </Page>
  </div>
</template>

<script>
import IconButton2 from "@/components/pager/IconButton2.vue";
export default {
  props: ["content"],
  components: {
    IconButton2,
  },
  data() {
    return {
      isMobile: window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i),
    }
  },
  methods: {
    goDetails(item) {
      if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) return
      this.$emit("goDetails", item);
    },
    pageChange(page) {
      this.$emit("pageChange", page);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ivu-page-item {
  &:hover {
    border-color: #dcdee2;
  }
  &:hover a {
    color: #515a6e;
  }
}
::v-deep .ivu-page-item-active {
  border-color: #2d8cf0 !important;
  &:hover a {
    color: #2d8cf0 !important;
  }
}
.item {
  margin-top: 22px;
  border-bottom: 1px dashed #d6d6d6;
  padding-bottom: 18px;
  .title {
    cursor: pointer;
    font-size: 20px;
    .column {
      color: #b6b6b6;
    }
  }
  .name {
    margin-left: 5px;
  }
  .info {
    margin: 10px 0;
    font-size: 14px;
    color: #999999;
    display: flex;
    align-items: center;
    .auther {
      max-width: 200px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 10px;
    }
  }
}
// /deep/
</style>
