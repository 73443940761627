<template>
  <div class="achievements-container">
    <div class="achievements">
      <div class="achievements-side">
        <Achievement
          @requestClick="requestClick2"
          @requestAll="requestAll1"
        ></Achievement>
        <Include @requestClick="requestClick" @requestAll="requestAll2"></Include>
        <Year @requestClick="requestClick1" @requestAll="requestAll3"></Year>
      </div>
      <div class="content-container">
        <div v-if="listData.total > 0">
          <div class="resultNum">
            检测到<span>{{ listData.total }}</span
            >条结果
          </div>
          <AchievementItem
            :content="listData"
            @goDetails="goDetails"
            @pageChange="pageChange"
          ></AchievementItem>
        </div>
        <div class="no-results" v-else>
          <img :src="require('@/assets/qietu/results.png')" alt="">
          <p>暂无成果</p>
        </div>
        <Spin size="large" fix v-show="spinShow"></Spin>
      </div>
    </div>

  </div>
</template>

<script>
import Year from "../components/Year.vue";
import Include from "../components/Include.vue";
import Achievement from "../components/Achievement.vue";
import AchievementItem from "../components/AchievementItem.vue";
export default {
  components: {
    Year,
    Include,
    Achievement,
    AchievementItem,
  },
  data() {
    return {
      listData: {},
      params: {
        userId: this.$route.query.id,
        pageNum: 1,
        pageSize: 8
      },
      spinShow: false
    };
  },
  created() {
    this.getArticleById(this.params);
  },
  methods: {
    async getArticleById(data) {
      this.spinShow = true
      const resp = await this.$apis.autherServe.getArticleById(data);
      this.listData = resp.data;
      this.spinShow = false
    },
    pageChange(page) {
      this.getArticleById({
        ...this.params,
        pageNum: page
      });
    },
    goDetails(item) {
      let url;
      if (item.articleType == 0) {
        if (item.type == 0) {
          url = this.$router.resolve({
            path: "/paperDetail",
            query: {
              articleType: item.articleType,
              articleId: item.id,
            },
          });
        } else if (item.type == 1) {
          url = this.$router.resolve({
            path: "/conferencepapers",
            query: {
              articleType: item.articleType,
              articleId: item.id,
            },
          });
        } else if (item.type == 2) {
          url = this.$router.resolve({
            path: "/conferenceppt",
            query: {
              articleType: item.articleType,
              articleId: item.id,
            },
          });
        } else if (item.type == 4) {
          url = this.$router.resolve({
            path: "/conferenceother",
            query: {
              articleType: item.articleType,
              articleId: item.id,
            },
          });
        }
      } else if (item.articleType == 1) {
        url = this.$router.resolve({
          path: "/patentdetail",
          query: {
            articleType: item.articleType,
            articleId: item.id,
          },
        });
      } else if (item.articleType == 2) {
        url = this.$router.resolve({
          path: "/monographdetail",
          query: {
            articleType: item.articleType,
            articleId: item.id,
          },
        });
      } else if (item.articleType == 3) {
        url = this.$router.resolve({
          path: "/reportdetail",
          query: {
            articleType: item.articleType,
            articleId: item.id,
          },
        });
      } else if (item.articleType == 4) {
        url = this.$router.resolve({
          path: "/standarddetail",
          query: {
            articleType: item.articleType,
            articleId: item.id,
          },
        });
      } else if (item.articleType == 5 && item.type == 0) {
        url = this.$router.resolve({
          path: "/informationdetail",
          query: {
            articleType: item.articleType,
            articleId: item.id,
          },
        });
      }
      window.open(url.href, "_blank");
    },
    requestClick(item) {
      this.params.dataStyle = item.dbName;
      this.getArticleById({
        ...this.params,
      });
    },
    requestAll1() {
      delete this.params.type;
      this.getArticleById({
        ...this.params,
      });
    },
    requestAll2() {
      delete this.params.dataStyle;
      this.getArticleById({
        ...this.params,
      });
    },
    requestAll3() {
      delete this.params.year;
      this.getArticleById({
        ...this.params,
      });
    },
    requestClick1(item) {
      this.params.year = item.name;
      this.getArticleById({
        ...this.params,
      });
    },
    requestClick2(item) {
      this.params.type = item.type;
      this.getArticleById(this.params);
    },
  },
};
</script>

<style lang="scss" scoped>
.achievements-container {
  .achievements{
    width: 100%;
    display: flex;
    align-items: flex-start;
    .content-container {
      position: relative;
      padding: 29px;
      margin-left: 39px;
      width: 100%;
      border: 1px dotted #999999;
      border-radius: 12px;
      background-color: #fff;
      .resultNum {
        font-size: 15px;
        color: #999999;
        font-weight: 400;
        span {
          color: #00a4ff;
        }
        margin-bottom: 14px;
      }
      .no-results {
        width: 100%;
        margin: 0 auto;
        img {
          width: 100%;
        }
        p {
          text-align: center;
          font-size: 16px;
          color: #999999;
        }
      }
    }
  }

}
</style>
