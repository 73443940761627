<template>
  <div class="side-box-container">
    <h2 class="title">收录类型</h2>
    <div
      class="box"
      v-for="(v, i) in comDataStyle"
      ref="sideLi"
      :key="i"
      :class="{ active: i === index }"
      @click="handleClick(v, i)"
    >
      <span class="name">{{ v.dbName }}</span>
      <span class="num">({{ v.num }})</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      index: -1,
      includeList: [],
      countList: [],
    };
  },
  created() {
    this.getCount({
      id: this.$route.query.id,
    });
    this.getIncludeList();
  },
  computed: {
    comDataStyle() {
      let result = this.includeList.map((item) => {
        let res;
        for (let i = 0; i < this.countList.length; i++) {
          const v = this.countList[i];
          if (item.dbName === v.value) {
            res = {
              dbName: item.dbName,
              num: v.count,
            };
          }
        }
        if (!res) {
          res = {
            dbName: item.dbName,
            num: 0,
          };
        }
        return res;
      });
      return result;
    },
  },
  methods: {
    handleClick(item, i) {
      this.index = i;
      let element = this.$refs.sideLi[this.index];
      if (element.classList.contains("active")) {
        element.classList.remove("active");
        this.$emit("requestAll");
      } else {
        element.classList.add("active");
        this.$emit("requestClick", item);
      }
    },
    async getIncludeList() {
      const resp = await this.$apis.searchServe.getIncludeList();
      this.includeList = resp.dbList;
    },
    async getCount(data) {
      const resp = await this.$apis.autherServe.getCount(data);
      this.countList = resp.data.dataStyleCount;
    },
  },
};
</script>

<style lang="scss" scoped>
.side-box-container {
  width: 260px;
  //   height: 390px;
  background: #ffffff;
  border: 1px dotted #999999;
  border-radius: 6px;
  padding: 35px 30px;
  position: relative;
  margin-bottom: 30px;
  user-select: none;
  &::after {
    position: absolute;
    left: -1px;
    top: -1px;
    content: "";
    display: block;
    width: 260px;
    height: 4px;
    background-image: url("~@/assets/imgs/squre.png");
    background-size: 100% 100%;
  }
  .title {
    font-size: 22px;
    font-weight: 400;
    color: #00a4ff;
    border-bottom: 28px;
  }
  .box {
    font-size: 18px;
    color: #333333;
    margin-top: 15px;
    cursor: pointer;
    &.active {
      color: #00a4ff;
    }
    .num {
      margin-left: 10px;
    }
  }
}
</style>
